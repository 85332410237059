export const STATUS_CREATING = "creating";
export const STATUS_STARTING = "starting";
export const STATUS_RUNNING = "running";
export const STATUS_SNAPSHOT = "snapshot";
export const STATUS_CHANGING_SUBSCRIPTION_PLAN = "changing-subscription-plan";
export const STATUS_UPDATING_VERSION = "updating-version";
export const STATUS_RESTORE = "restore";
export const STATUS_CHANGE_REGION = "change-region";
export const STATUS_SUSPENDING = "suspending";
export const STATUS_SUSPENDED = "suspended";
export const STATUS_STOPPING = "stopping";
export const STATUS_STOPPED = "stopped";
export const STATUS_DELETING = "deleting";
export const STATUS_DELETED = "deleted";
export const STATUS_DELETE_FAILED = "delete-failed";
export const STATUS_ERROR = "error";

export const STATUSES = [
  STATUS_CREATING,
  STATUS_STARTING,
  STATUS_RUNNING,
  STATUS_SNAPSHOT,
  STATUS_CHANGING_SUBSCRIPTION_PLAN,
  STATUS_UPDATING_VERSION,
  STATUS_RESTORE,
  STATUS_CHANGE_REGION,
  STATUS_SUSPENDING,
  STATUS_SUSPENDED,
  STATUS_STOPPING,
  STATUS_STOPPED,
  STATUS_DELETING,
  STATUS_DELETED,
  STATUS_DELETE_FAILED,
  STATUS_ERROR,
] as const;

export const MIGRATION_STATUS_INITIALIZED = "initialized";
export const MIGRATION_STATUS_MIGRATING = "migrating";
export const MIGRATION_STATUS_SNAPSHOT_UPLOADED = "snapshot-uploaded";
export const MIGRATION_STATUS_COMPLETED = "completed";
export const MIGRATION_STATUS_MIGRATION_FAILED = "migration-failed";
export const MIGRATION_STATUS_CANCELLED = "cancelled";

export const MIGRATION_STATUSES = [
  MIGRATION_STATUS_INITIALIZED,
  MIGRATION_STATUS_MIGRATING,
  MIGRATION_STATUS_SNAPSHOT_UPLOADED,
  MIGRATION_STATUS_COMPLETED,
  MIGRATION_STATUS_MIGRATION_FAILED,
  MIGRATION_STATUS_CANCELLED,
] as const;

export const HEALTH_PROVISIONING = "PROVISIONING";
export const HEALTH_HEALTHY = "HEALTHY";
export const HEALTH_UNKNOWN = "UNKNOWN";
export const HEALTH_UNHEALTHY = "UNHEALTHY";
export const HEALTH_DNSFAIL = "DNSFAIL";
export const HEALTH_DELETED = "DELETED";

export const HEALTHS = [
  HEALTH_PROVISIONING,
  HEALTH_HEALTHY,
  HEALTH_UNKNOWN,
  HEALTH_UNHEALTHY,
  HEALTH_DNSFAIL,
  HEALTH_DELETED,
] as const;

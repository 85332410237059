import { PurchasesStore } from "./types";
import { comparePurchase } from "./utils";
import { useMutation, useQuery, useQueryClient } from "@common/utils/use-query";

export const purchasesStore: PurchasesStore = {
  // User needs to be authenticated (the account already exists)
  useCheckInstanceDNSAliasAvailability(
    dns_alias,
    { enabled } = { enabled: false },
  ) {
    return useQuery(
      `/account/purchases/action/instance_dns_available?dns_alias=${dns_alias}`,
      {
        enabled,
        onError(error) {
          if (error.status === 409) {
            error.message = "Looks like that DNS alias is already taken.";
          }
        },
      },
    );
  },

  // Public endpoint, rate limited
  useCheckDNSAvailability(dns: string, { enabled } = { enabled: false }) {
    return useQuery(`/account/instance_dns_available?dns_alias=${dns}`, {
      enabled,
      onError(error) {
        if (error.status === 409) {
          error.message = "Looks like that DNS alias is already taken.";
        }

        if (error.status === 429) {
          error.message = "Too many requests! Please try again later.";
        }
      },
    });
  },

  useCreatePurchase() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/account/purchases",
        method: "POST",
      },
      {
        onError(error) {
          if (
            error.message.indexOf("already running") > -1 &&
            error.message.indexOf("trial") > -1
          ) {
            error.status = 402;
          }
        },
        onSuccess() {
          return queryClient.invalidateQueries("/account/purchases");
        },
      },
    );
  },

  useGetPurchases({ enabled } = { enabled: true }) {
    const queryClient = useQueryClient();
    return useQuery("/account/purchases", {
      enabled,
      onSuccess(data) {
        queryClient.setQueryData(
          "/account/purchases",
          data.sort(comparePurchase),
        );
      },
    });
  },

  useGetPurchase(id, { enabled } = { enabled: true }) {
    const queryClient = useQueryClient();
    return useQuery(`/account/purchases/${id}`, {
      enabled,
      onSuccess() {
        return queryClient.invalidateQueries("/account/purchases");
      },
    });
  },

  useMigrateInstance(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/initiate_instance_migration`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useCancelMigration(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/cancel_instance_migration`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useCancelPurchase(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}`,
        method: "DELETE",
      },
      {
        onSuccess() {
          queryClient.refetchQueries(`/account/purchases/${id}`);
          return queryClient.refetchQueries("/account/purchases");
        },
      },
    );
  },

  useSendPostCancellationSurvey(id) {
    return useMutation({
      url: `/account/purchases/${id}/cancellation_survey`,
      method: "POST",
    });
  },

  useReactivatePurchase(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/resurrect`,
        method: "PUT",
      },
      {
        onError() {
          queryClient.refetchQueries(`/account/purchases/${id}`);
          return queryClient.refetchQueries("/account/purchases");
        },
        onSuccess() {
          queryClient.refetchQueries(`/account/purchases/${id}`);
          return queryClient.refetchQueries("/account/purchases");
        },
      },
    );
  },

  useChangeInstanceDNSAlias(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/change_instance_dns_alias`,
        method: "PUT",
      },
      {
        onError(error) {
          if (error.status === 409 || error.status === 400) {
            error = {
              message: "Looks like that DNS alias is already taken.",
              status: 409,
            };
          }
        },
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useChangeInstanceDomain(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/change_instance_custom_domain`,
        method: "PUT",
      },
      {
        onError(error) {
          if (error.status === 409 || error.status === 400) {
            error = {
              message: "Looks like that custom domain is already taken.",
              status: 409,
            };
          }
        },
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useChangeInstanceRegion(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/change_region`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useTryProPlan(id) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/trial_up`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useChangePlan(id: string) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/account/purchases/${id}/action/change_plan`,
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries(`/account/purchases/${id}`);
        },
      },
    );
  },

  useGetPlanChangeEstimate(id) {
    return useMutation({
      url: `/account/purchases/${id}/action/change_plan_preview`,
      method: "PUT",
    });
  },
};
